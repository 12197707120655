<template>
  <div class="container py-3">
    <div v-if="!allAccounts">
      <h3 class="text-primary text-center">
        <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        Please wait...
      </h3>
    </div>

    <div class="" v-if="allAccounts">
      <!-- large screens  -->
      <div class="d-none d-lg-block d-xl-block">

        <div class="table-responsive bg-white">
          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-start">
            <h1 class="navbar-brand">&nbsp;ACCOUNTS</h1>
          </nav>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Acc No.</th>
                <th scope="col">Type</th>
                <th scope="col">Balance</th>
                <th scope="col">Last Updated</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tran, i) in this.allAccounts" :key="i">
                <th scope="row">{{ i + 1 }}. </th>
                <td>{{ tran.account_number }}</td>
                <td>{{ tran.type }}</td>
                <td><strong>{{ tran.balance ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'UGX'
                }).format(tran.balance,) : "N/A" }}</strong></td>
                <td>{{ new Date().toLocaleString() }}</td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <!-- small screens -->
      <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">

        <div class="table-responsive bg-white">
          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
            <h1 class="navbar-brand">&nbsp;ACCOUNTS</h1>
          </nav>
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Acc No.</th>
                <th scope="col">Type</th>
                <th scope="col">Balance</th>
                <!-- <th scope="col">Date Time</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tran, i) in this.allAccounts" :key="i">
                <th scope="row">{{ i + 1 }}. </th>
                <td>{{ tran.account_number }}</td>
                <td>{{ tran.type }}</td>
                <td><strong>{{ tran.balance ? new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'UGX'
                }).format(tran.balance,) : "N/A" }}</strong></td>
                <!-- <td>{{ new Date().toLocaleString() }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AccountsView",
  data() {
    return {}
  },
  methods: {
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    ...mapActions({
      Accounts: 'fetchAccounts'
    })
  },
  computed: {
    ...mapGetters([
      'allAccounts'
    ])
  },
  created() {
    this.Accounts().then(() => { })
      .catch(err => {
        if (err.code == 'ERR_NETWORK') {
          this.error(err.code, err.message)
        } else {
          this.error(err.code, '')
        }
      })
  }
}
</script>

<style scoped></style>
