<template>
  <!-- <nav class="navbar navbar-expand-lg bg-light shadow-sm shadow-dark-mode-none" v-if="isLogged"> -->
  <!-- <Card v-if="isLogged"> -->
  <div class="d-flex justify-content-between align-items-center bg-white" v-if="isLogged">

    <div class="navbar-brand d-none d-lg-block">
      <router-link to="/">
        <img alt="Logo" class="ml-5" src="@/assets/logo.png" width="80" :style="{ 'background-image': '#03253D' }">
      </router-link>

      <span :style="{ 'color': '#03253D', 'font-weight': '800' }">
        {{ this?.userIn ? this?.userIn?.business?.name?.toUpperCase() : "N/A" }}
      </span>
    </div>

    <div>
      <span class="d-none d-lg-block">
        <Menu mode="horizontal" :theme="theme" active-name="1">
          <MenuItem name="Dashboard" to="/">
          <Icon type="ios-home" />
          Dashboard
          </MenuItem>

          <MenuItem name="Loans" to="/loans">
          <Icon type="ios-list-box" />
          Loans
          </MenuItem>

          <MenuItem name="Accounts" to="/accounts">
          <Icon type="ios-list-box" />
          Accounts
          </MenuItem>

          <MenuItem name="Shares" to="/shares">
          <Icon type="ios-list-box" />
          Shares
          </MenuItem>

          <Submenu name="Requests">
            <template #title>
              <Icon type="ios-stats" />
              Requests
            </template>
            <MenuGroup title="TYPES">
              <MenuItem name="Loan Requests" to="/requests?request_type=loan">Loan Requests</MenuItem>
              <MenuItem name="Withdraw Requests" to="/requests?request_type=withdraw">Withdraw Requests</MenuItem>
            </MenuGroup>
          </Submenu>

          <MenuItem name="Transactions" to="/transactions">
          <Icon type="md-swap" />
          Transactions
          </MenuItem>

          <MenuItem name="Statements" to="/statement">
          <Icon type="ios-document" />
          Statement
          </MenuItem>

          <Submenu name="Others">
            <template #title>
              <Icon type="ios-contact" size="20" />
              {{ this?.userIn?.user?.username ?? 'User' }}
            </template>

            <MenuGroup title="TYPES">
              <MenuItem name="Profile" to="/profile">
              <Icon type="ios-contact" />
              Profile
              </MenuItem>

              <!-- <MenuItem name="Settings" to="/settings"> -->
              <!-- <Icon type="ios-settings" /> -->
              <!-- Settings -->
              <!-- </MenuItem> -->

              <MenuItem name="Logout" @click="logout()">
              <Icon type="md-exit" />
              <span class="fw-bold text-danger">Log Out</span>

              </MenuItem>

            </MenuGroup>
          </Submenu>
        </Menu>
      </span>
    </div>
  </div>

  <!-- hide on lg and wider screens -->
  <span class="d-lg-none" v-if="isLogged">
    <Card>
      <div class="d-flex justify-content-between align-items-center">
        <div>
          <router-link class="" to="/">
            <img alt="Logo" src="@/assets/logo.png" :style="{ width: '25%' }" />
          </router-link>
        </div>

        <div>
          <Dropdown placement="bottom-end" trigger="click">
            <!-- <Avatar icon="ios-person" size="large" :style="{ background: '#03253D', 'margin-left': '-50px' }" /> -->
            <Icon type="ios-menu" size="40" color="#03253D" :style="{ width: '25%' }" />
            <template #list>
              <DropdownMenu>
                <router-link to="/">
                  <DropdownItem>Dashboard</DropdownItem>
                </router-link>
                <router-link to="/profile">
                  <DropdownItem>My Profile</DropdownItem>
                </router-link>
                <!-- <router-link to="/settings"> -->
                <!--   <DropdownItem>My Settings</DropdownItem> -->
                <!-- </router-link> -->
                <router-link to="/accounts">
                  <DropdownItem>Accounts</DropdownItem>
                </router-link>
                <router-link to="/loans">
                  <DropdownItem>Loans</DropdownItem>
                </router-link>
                <router-link to="/shares">
                  <DropdownItem>Shares</DropdownItem>
                </router-link>
                <router-link to="/statement">
                  <DropdownItem>My Statement</DropdownItem>
                </router-link>
                <router-link to="/requests?request_type=loan">
                  <DropdownItem>Loan Requests</DropdownItem>
                </router-link>
                <router-link to="/requests?request_type=withdraw">
                  <DropdownItem>Withdraw Requests</DropdownItem>
                </router-link>
                <router-link to="/transactions">
                  <DropdownItem>
                    Transactions
                  </DropdownItem>
                </router-link>
                <DropdownItem divided @click="logout()">
                  <a href="javascript:;" class="fw-bold text-danger" @click="logout()">Log Out</a>
                </DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </div>
      </div>
    </Card>
  </span>
  <!-- </nav> -->
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NavBar",
  data() {
    return {}
  },
  methods: {
    logout() {
      this.Logout()
    },
    ...mapActions({
      Logout: 'SignOut'
    })
  },
  computed: {
    ...mapGetters([
      'isLogged', 'userIn'
    ])
  }
}
</script>

<style scoped></style>
