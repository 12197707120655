<template>

  <Modal v-model="openBusinessSelectModal" title="Select SACCO" footer-hide=true :closable="false"
    :mask-closable="false" :loading="!this.getUserBusinesses">
    <List header="" footer="" border size="large">
      <ListItem v-for="(business, idx) in this.getUserBusinesses" :key="idx"
        @click="handleSelectBusiness(business?.number)">{{ business?.name }}</ListItem>
    </List>

    <!-- {{ this.getUserBusinesses }} -->
  </Modal>

</template>

<script>
import { /* mapActions, */ mapGetters } from "vuex";

export default {
  name: 'SelectBusinessView',
  components: {},
  data() {
    return {
      loading: true,
      openBusinessSelectModal: true,
      clientBusinesses: []
    }
  },
  methods: {
    handleSelectBusiness(businessNumber) {
      this.$Loading.start()

      // console.log(businessNumber)
      // debugger

      this.$store
        .dispatch('fetchClient', { businessNumber })
        .then(() => {

          this.$Loading.finish();

          this.openBusinessSelectModal = false;

          this.$router.push({ name: "home", replace: true })
          setTimeout(() => {
            this.$router.go()
          }, 1000)

        })
        .catch(() => {

          this.$Loading.error();

        })
    },
  },
  computed: {
    ...mapGetters([
      'getUserBusinesses',
    ])
  },

  created() {
    this.$watch(
      () => this.$route,
      () => {
        // react to route changes...
        this.clientBusinesses = this.getUserBusinessses;
      })

  }
}
</script>

<style scoped></style>
